<template>
  <div>
    <div class="card">
      <div class="card-body form-card">
        <div class="row">
          <div class="col-4">
            <el-input v-model="form.sync_user_id" placeholder="User ID"></el-input>
          </div>
          <div class="col-4">
            <el-input v-model="form.sync_activity_id" placeholder="Activity ID"></el-input>
          </div>
          <div class="col-4">
            <el-button class="full-width" type="info" icon="el-icon-refresh" @click="syncActivity">
              Đồng bộ
            </el-button>
          </div>
        </div>

      </div>
    </div>

    <div class="card">
      <div class="card-body form-card">
        <div class="row">
          <div class="col-3">
            <el-input v-model="form.user_id" placeholder="User ID"></el-input>
          </div>
          <div class="col-3">
            <el-input v-model="form.strava_id" placeholder="Strava ID"></el-input>
          </div>
          <div class="col-3">
            <el-input v-model="form.activity_id" placeholder="Activity ID"></el-input>
          </div>
          <div class="col-3">
            <el-button class="full-width" type="primary" icon="el-icon-info" @click="viewUserInfo">
              Xem thông tin
            </el-button>
          </div>
        </div>

        <div class="user-info" v-if="isShowUserInfo">

          <div class="row">
            <div class="col-2">
              <label>Thông tin User</label>
            </div>
            <div class="col-10">
              <table class="table table-bordered">
                <tr>
                  <td>User ID</td>
                  <td><a :href="'https://irace.vn/races/users/' + this.user.user.id" target="_blank">{{ this.user.user.id }}</a></td>
                </tr>
                <tr>
                  <td>Thông tin</td>
                  <td>{{ this.user.user.name }}</span> - <span>{{ this.user.user.email }}</span> - <span>{{ this.user.user.phone }}</span></td>
                </tr>
                <tr>
                  <td>Ngày tạo tài khoản</td>
                  <td>{{ this.user.user.created_at }}</td>
                </tr>
                <tr>
                  <td>Ứng dụng đã kết nối</td>
                  <td>{{ this.user.app || 'Chưa kết nối ứng dụng nào' }}</td>
                </tr>
              </table>
            </div>
          </div>

          <div class="row">
            <div class="col-2">
              <label>Strava</label>
            </div>
            <div class="col-10">
              <table class="table table-bordered">
                <tr>
                  <td>Strava ID</td>
                  <td><a :href="'https://www.strava.com/athletes/' + this.user.user.strava_id" target="_blank"> {{ this.user.user.strava_id }}</a></td>
                </tr>
                <tr>
                  <td>Kết nối lần cuối</td>
                  <td>{{ this.user.user.strava_connected_at }}</td>
                </tr>
                <tr>
                  <td>Kết nối lần đầu</td>
                  <td>{{ this.user.user.strava_first_connected_at }}</td>
                </tr>
              </table>
            </div>
          </div>

          <div class="row">
            <div class="col-2">
              <label>5 sự kiện gần nhất</label>
            </div>
            <div class="col-10">
              <el-table
                :data="this.user.races"
                border
                style="width: 100%">
                <el-table-column
                  prop="race_title"
                  label="Sự kiện"
                >
                </el-table-column>
                <el-table-column
                  prop="total_payment"
                  label="Thanh toán">
                  <template slot-scope="scope">{{ $util.formatMoney(scope.row.total_payment) }}</template>
                </el-table-column>
                <el-table-column
                  prop="created_at"
                  label="Thời gian"
                >
                </el-table-column>
              </el-table>
            </div>
          </div>

          <div class="row">
            <div class="col-2">
              <label>5 hoạt động được sync gần nhất</label>
            </div>
            <div class="col-10">
              <el-table
                :data="this.user.activities"
                border
                style="width: 100%">
                <el-table-column
                  prop="id"
                  label="Activity ID"
                >
                <template slot-scope="scope">
                  <a :href="'https://www.strava.com/activities/' + scope.row.id" target="_blank">{{ scope.row.id }}</a>
                </template>
                </el-table-column>
                <el-table-column
                  prop="name"
                  label="Tên hoạt động"
                >
                </el-table-column>
                <el-table-column
                  prop="created_at"
                  label="Thời gian"
                >
                </el-table-column>
              </el-table>
            </div>
          </div>

        </div>

      </div>
    </div>

    <loading :active.sync="isLoading"></loading>

  </div>
</template>

<script>
  import { Message, Button, Input, Table, TableColumn } from 'element-ui';
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';

  export default {
    components: {
      Message,
      Loading,
      ElButton: Button,
      ElInput: Input,
      ElTable: Table,
      ElTableColumn: TableColumn
    },

    data() {
      return {
        form: {},
        user: {
          user: {},
          app: '',
          races: [],
          activities: []
        },
        isLoading: false,
        isShowUserInfo: false
      }
    },

    methods: {

      syncActivity() {
        if (!this.form.sync_user_id) {
          return Message({
            type: 'error',
            message: 'Chưa nhập User ID'
          });
        }

        let obj = {
          user_id: this.form.sync_user_id,
          activity_id: this.form.sync_activity_id
        };

        this.isLoading = true;
        this.$store.dispatch('syncUserActivities', obj).then(res => {
          this.isLoading = false;
          if (res.success) {
            Message({
              type: 'success',
              message: res.message
            });
          } else {
            Message({
              type: 'error',
              message: res.message
            });
          }
        });
      },

      viewUserInfo() {
        if (!this.form.user_id && !this.form.strava_id && !this.form.activity_id) {
          return Message({
            type: 'error',
            message: 'Chưa nhập thông tin để kiểm tra'
          });
        }

        var obj = {
          user_id: this.form.user_id,
          strava_id: this.form.strava_id,
          activity_id: this.form.activity_id
        }

        this.isLoading = true;
        this.isShowUserInfo = false;
        this.$store.dispatch('getUserInfo', obj).then(res => {
          this.isLoading = false;
          console.log(res);
          if (res.success) {
            this.user = Object.assign({}, res.data);
            console.log(this.user);
            this.isShowUserInfo = true;
          } else {
            Message({
              type: 'error',
              message: res.message
            });
          }
        });

      }

    },

    mounted() {
      window.AP = this;
      this.$store.dispatch('setPageTitle', 'Support Tool');
      this.$store.dispatch('setCurrentFilterUsers', []);
      this.$store.dispatch('setCurrentActions', []);
    },

    destroyed() {
      this.$store.dispatch('setCurrentActions', []);
    }
  }

</script>
<style lang="scss" scoped>
  .el-table .td-actions {
    button.btn {
      margin-right: 5px;
    }
  }

  .filter-group-bill {
    padding: 15px;
  }

  .import-status {
    width: 60%;
    margin: 20px auto 0;
  }

  .user-info {
    padding: 20px 0;
    div {
      margin-bottom: 5px;
    }
  }

</style>
